.Simulator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}
.Simulator-title {
    text-align: center;
}
.Simulator-title h2 {
    /* margin-: 50px 350px; */
}
.Simulator-content {
    margin: auto;
    padding: 40px 50px;
    background: white;
}

.Simulator-description {
    margin-bottom: 30px;
    text-align: left;
}
.Simulator-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0;
}
.Simulator-form-badge {
    background-color: #a1c331;
    width: 55px;
    height: 55px;
    text-align: center;
    color: white;
    font-size: 38px;
    border-radius: 50%;
    margin-right: 20px;
}
.Simulator-form-step {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 20px 0;
    font-size: 25px;
    font-weight: 500;
}
.Simulator-form-step-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.Simulator-form-step-text {}

.Simulator-form-step-input {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
}
.Simulator-form-step-input span {
    color: #57665E;
}
.Simulator-form-step-input input, select {
    height: 35px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 2px solid #f5f5f5;
    max-width: 500px;
    padding: 0 10px;
}

.Simulator-form-step-input select {
    height: 43px;
    max-width: 525px;
    background-color: white;
}
.input-group {
    display: flex;
    flex-direction: column;
}

.Simulator-form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 525px;
}
.Simulator-form-actions-init {
    height: 50px;
    width: 200px;
    border: 0;
    background: #a1c331;
    color: white;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .Simulator {
        overflow-x: hidden;
        margin-bottom: 0;
    }

    .Simulator-title h2 {
        margin: 10px;
        font-size: 15px;
    }
    .Simulator-description span {
        font-size: 13px;
    }

    .Simulator-content {
        margin: 0;
        padding: 10px;
        background: white;
        /* z-index: -1; */
    }
    .Simulator-form {
        padding: 0;
    }
    .Simulator-form-step {
        margin: 0;
        font-weight: 700;
        font-size: 12px;
        margin: 10px 0;
        width: 100%;
    }
    .Simulator-form-step-title {
        padding: 10px 0;
        margin-bottom: 0;
    }
    .Simulator-form-badge {
        width: 25px;
        height: 25px;
        font-size: 16px;
        margin-right: 5px;
        padding-top: 3px;
    }
    .Simulator-form-step-input {
        margin-left: 5px;
    }

    .Simulator-form-step-input input {
        width: auto;
    }

    .Simulator-form-step-input select {
        width: auto;
    } 
    
    .Simulator-form-actions-init {
        width: 100%;
        margin: 0 0 0 5px;
    }
}